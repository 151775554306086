<template>
    <div
        style="padding: 220px 220px 0px 220px;"
        @click="routeParams = {}"
    >
        <v-row v-if="loadedConfig && loadedConfig.homeLogo && loadedConfig.secondaryLogo" class="d-flex flex-column">
            <v-col  class="d-flex justify-end">
                <img
                    :src="loadedConfig.homeLogo"
                    height="108px"
                    width="100%"
                />
            </v-col>
            <v-col
                v-if="loadedConfig && loadedConfig.secondaryLogo"
                class="d-flex justify-end"
            >
                <img
                    :src="loadedConfig.secondaryLogo"
                    :height="loadedConfig.secondaryLogoHeight || '29px'"
                    :width="loadedConfig.secondaryLogoWidth ||  '116px'"
                />
            </v-col>
        </v-row>
        <v-row v-else-if="loadedConfig && loadedConfig.homeLogo">
            <v-col class="d-flex justify-end">
                <img
                    :src="loadedConfig.homeLogo"
                    height="137px"
                    width="100%"
                />
            </v-col>
        </v-row>
        <v-row
            v-if="routeParams && routeParams.firstName && routeParams.lastName && routeParams.custumerId"
            style="padding-top: 50px;"
            class="d-flex flex-column"
        >
            <v-col class="d-flex justify-center customerNumberStyle" :style="fontColorStyle">
                N ° {{ routeParams.custumerId }}
            </v-col>
            <v-col class="d-flex justify-center customerNameStyle" :style="fontColorStyle">
                {{ routeParams.firstName }} {{ routeParams.lastName }}
            </v-col>
        </v-row>
        <v-row
            v-else
            style="padding-top: 65px;"
        >
            <v-col v-if="loadedConfig && loadedConfig.buttonColor" class="d-flex justify-center">
                <v-btn
                    :color="loadedConfig.buttonColor || 'white'"
                    style="width: 329px; height: 115px; border-radius: 30px;"
                    dark
                    height="115px"
                    width="100%"
                    @click="registerUser()"
                >
                    <div class="d-flex">
                        <div class="pr-1">
                            <icon
                                name="fa/solid/user-alt"
                                :color="loadedConfig.buttonColorInner || 'white'"
                                height="52px"
                                width="59px"
                            />
                        </div>
                        <div class="pl-1">
                            <icon
                                name="fa/solid/plus"
                                :color="loadedConfig.buttonColorInner || 'white'"
                                height="52px"
                                width="59px"
                            />
                        </div>
                    </div>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import Icon from '@wisol/icons'
import icons from '@wisol/icons/src/icons'
Icon.register(icons)
export default {
  name: 'App',
  props: {
      loadedConfig: {
          type: Object,
          required: false,
          default: () => {}
      }
  },
  components: {
      Icon
  },
  data () {
      return {
          routeParams: {}
      }
  },
  computed: {
      fontColorStyle () {
          let color = 'white'
          if (this.loadedConfig && this.loadedConfig.fontColor) {
              color = this.loadedConfig.fontColor
          }
          return {
              color: color
          }
      },
  },

  mounted () {
      this.routeParams = this.$router.currentRoute.params
      if (this.routeParams) {
          setTimeout(() => {
              this.routeParams = {
                  branch: this.routeParams.branch
              }
          }, 30000);
      }
  },

  methods: {
      registerUser () {
         this.$router.push({
             name: 'register'
         })
      }
  }
};
</script>
<style>
.customerNumberStyle {
    font: normal normal 100 117px/155px Roboto;
}
.customerNameStyle {
    font: normal normal 100 55px/73px Roboto;
}
</style>
