<template>
    <v-container style="padding-top: 60px;">
        <v-row v-if="$vuetify.breakpoint.smAndDown" :class="{'cornerslogo': $vuetify.breakpoint.smAndDown}">
            <v-col sm="5" cols="5">
                <v-row class="pa-0 d-flex justify-start">
                    <v-col sm="9" cols="9" class="ml-1 pl-0">
                        <img v-if="loadedConfig && loadedConfig.registerLogoLeft" :src="loadedConfig.registerLogoLeft" height="32px" @click="goToMainMenu()" />
                        <img v-else-if="loadedConfig && loadedConfig.homeLogo" :src="loadedConfig.homeLogo" height="32px" @click="goToMainMenu()" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col sm="5" cols="5">
                <v-row class="pa-0 d-flex justify-end">
                    <v-col sm="5" cols="5" class="mr-1 pr-0">
                        <img
                          v-if="loadedConfig && loadedConfig.registerLogoRight"
                          :src="loadedConfig.registerLogoRight"
                          :height="loadedConfig.registerLogoRightHeight || '29px'"
                          :width="loadedConfig.registerLogoRightWidth || '200px'"
                        />
                        <img
                          v-else-if="loadedConfig && loadedConfig.secondaryLogo"
                          :src="loadedConfig.secondaryLogo"
                          :height="loadedConfig.registerLogoRightHeight || '29px'"
                          :width="loadedConfig.registerLogoRightWidth || '200px'"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-if="!$vuetify.breakpoint.smAndDown">
            <v-col xl="6" lg="6" md="6">
                <v-row class="pa-0 d-flex justify-start">
                    <v-col xl="6" lg="6" md="6" class="ml-1 pl-0 d-flex justify-start" >
                        <img
                          v-if="loadedConfig && loadedConfig.registerLogoLeft"
                          :src="loadedConfig.registerLogoLeft"
                          height="32px"
                          @click="goToMainMenu()"
                        />
                        <img
                          v-else-if="loadedConfig && loadedConfig.homeLogo"
                          :src="loadedConfig.homeLogo"
                          height="32px"
                          @click="goToMainMenu()"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col xl="6" lg="6" md="6">
                <v-row class="pa-0 d-flex justify-end">
                    <v-col xl="6" lg="6" md="6" class="mr-1 pr-0 d-flex justify-end">
                        <img
                          v-if="loadedConfig && loadedConfig.registerLogoRight"
                          :src="loadedConfig.registerLogoRight"
                          :height="loadedConfig.registerLogoRightHeight || '14px'"
                          :width="loadedConfig.registerLogoRightWidth || '200px'"
                        />
                        <img
                          v-else-if="loadedConfig && loadedConfig.secondaryLogo"
                          :src="loadedConfig.secondaryLogo"
                          :height="loadedConfig.registerLogoRightHeight || '29px'"
                          :width="loadedConfig.registerLogoRightWidth || '200px'"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="pt-5">
            <v-form style="width: 100%;">
                <v-row class="d-flex justify-center">
                    <v-col style="flex: 1;">
                        <v-row class="d-flex flex-column">
                            <v-col class="pb-0" :style="fieldsTitle">
                                Nom
                            </v-col>
                            <v-col class="pt-0">
                                <v-text-field
                                    ref="nom"
                                    v-model="nom"
                                    solo
                                    hide-details
                                    :style="fieldsStyle"
                                    height="20px"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col style="flex: 1;">
                        <v-row class="d-flex flex-column">
                            <v-col class="pb-0" :style="fieldsTitle">
                                Prénom
                            </v-col>
                            <v-col class="pt-0">
                                <v-text-field
                                    v-model="prenom"
                                    solo
                                    hide-details
                                    :style="fieldsStyle"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col style="flex: 1;">
                        <v-row class="d-flex flex-column">
                            <v-col class="pb-0" :style="fieldsTitle">
                                E-Mail
                            </v-col>
                            <v-col class="pt-0">
                                <v-text-field
                                    v-model="email"
                                    type="email"
                                    solo
                                    hide-details
                                    :style="fieldsStyle"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col style="flex: 1;">
                        <v-row class="d-flex flex-column">
                            <v-col class="pb-0" :style="fieldsTitle">
                                Mobil
                            </v-col>
                            <v-col class="pt-0">
                                <v-text-field
                                    v-model="phone"
                                    solo
                                    hide-details
                                    :style="fieldsStyle"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col style="flex: 1;">
                        <v-row class="d-flex flex-column">
                            <v-col class="pb-0" :style="fieldsTitle">
                                Date de naissance
                            </v-col>
                            <v-col class="pt-0" >
                                <v-menu
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="-35"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="computedDateFormatted"
                                            readonly
                                            solo
                                            v-bind="attrs"
                                            v-on="on"
                                            hide-details
                                            :style="fieldsStyle"
                                        />
                                    </template>
                                    <v-date-picker
                                        ref="picker"
                                        v-model="date"
                                        color="#2E2D2C"
                                        locale="fr"
                                        :max="new Date().toISOString().substr(0, 10)"
                                        min="1900-01-01"
                                        @input="menu = false"
                                    />
                                  </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="pt-4" style="display: flex;">
                    <v-col style="flex: 0.35;">
                        <v-row class="d-flex flex-column">
                            <v-col class="pb-0" :style="fieldsTitle">
                                N°
                            </v-col>
                            <v-col class="pt-0">
                                <v-text-field
                                    v-model="inputNumero"
                                    solo
                                    hide-details
                                    :style="fieldsStyle"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col style="flex: 1.65;">
                        <v-row class="d-flex flex-column">
                            <v-col class="pb-0" :style="fieldsTitle">
                                Rue
                            </v-col>
                            <v-col class="pt-0">
                                <v-combobox
                                    v-model="inputrue"
                                    :items="filteredRueMappedAddresses"
                                    item-text="RUE"
                                    item-value="full"
                                    hide-details
                                    background-color="white"
                                    :no-filter="true"
                                    :search-input.sync="inputRueSearch"
                                    solo
                                    :menu-props="{
                                        maxHeight: '50%',
                                        closeOnContentClick: true,
                                        closeOnClick: true,
                                        value: filteredRueMappedAddressesSet
                                    }"
                                    :style="fieldsStyle"
                                    @keyup="input($event)"
                                >
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.full }}
                                    </template>
                                </v-combobox>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col style="flex: 1;">
                        <v-row class="d-flex flex-column">
                            <v-col class="pb-0" :style="fieldsTitle">
                                Code Postal
                            </v-col>
                            <v-col class="pt-0">
                                <v-combobox
                                    v-model="inputcodepostal"
                                    :items="mappedAddresses"
                                    item-value="plz"
                                    item-text="plz"
                                    hide-details
                                    background-color="white"
                                    solo
                                    :menu-props="{ maxHeight: '50%' }"
                                    :style="fieldsStyle"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col style="flex: 1;">
                        <v-row class="d-flex flex-column">
                            <v-col class="pb-0" :style="fieldsTitle">
                                Localité
                            </v-col>
                            <v-col class="pt-0">
                                <v-combobox
                                    v-model="inputlocalite"
                                    :items="mappedAddresses"
                                    item-value="LOCALITE"
                                    item-text="LOCALITE"
                                    hide-details
                                    background-color="white"
                                    solo
                                    :menu-props="{ maxHeight: '50%' }"
                                    :style="fieldsStyle"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col style="flex: 1;">
                        <v-row class="d-flex flex-column">
                            <v-col class="pb-0" :style="fieldsTitle">
                                Pays
                            </v-col>
                            <v-col class="pt-0">
                                <v-combobox
                                    v-model="inputpays"
                                    :items="countrys"
                                    item-value="code"
                                    item-text="name"
                                    hide-details
                                    background-color="white"
                                    solo
                                    :menu-props="{ maxHeight: '50%' }"
                                    :style="fieldsStyle"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="pt-4" style="display: flex;">
                    <v-col style="flex: 1;">
                        <v-row class="d-flex">
                            <v-col class="title d-flex justify-start align-center pr-0 pt-0" style="flex: 0.01;">
                                <v-checkbox
                                    v-model="termsAndConditions"
                                    color="success"
                                    :dark="(loadedConfig && loadedConfig.darkTheme) || false"
                                    large
                                    hide-details
                                />
                            </v-col>
                            <v-col class="pl-1 d-flex align-end" style="flex: 0.9;">
                                <div class="pr-1" @click="termsAndConditions = !termsAndConditions" :style="fontColorStyle">J'ai lu et accepté</div> <div :style="fontColorStyle">les</div><b class="pl-1" @click="termsAndConditionsDialog = true"><u :style="fontColorStyle">{{ loadedConfig.termsAndConditionsUnderlineText || "conditions générales d'utilisation" }}</u></b>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col style="flex: 0.235">
                        <v-row class="d-flex justify-center ma-0 align-end">
                            <v-btn
                                :style="fieldsStyle"
                                height="72px"
                                width="100%"
                                :disabled="!canSubmit"
                                @click="submitForm()"
                            >
                                Confirmer
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-row>
            </v-form>
        </v-row>
        <v-dialog
            v-model="termsAndConditionsDialog"
            width="500"
            scrollable
        >
            <v-card>
                <v-card-title class="headline" :style="backgroundColorStyle">
                    <div :style="fontColorStyle">Conditions Générales d'utilisation</div>
                </v-card-title>

                <v-card-text class="pt-4 pb-4" style="text-align: justify;">
                    {{ loadedConfig.termsAndConditions }}
                </v-card-text>

                <v-divider />
                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        color="#BE1622"
                        class="white--text"
                        @click="termsAndConditionsDialog = false"
                    >
                        Annuler
                    </v-btn>
                    <v-btn
                        color="green"
                        class="white--text"
                        @click="termsAndConditionsAccepted()"
                    >
                        Accepter
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div v-if="loadscreen">
            <v-overlay
                absolute
                :value="loadscreen"
            >
                <v-progress-circular
                    :size="100"
                    :width="5"
                    indeterminate
                    color="primary"
                />
            </v-overlay>
        </div>
    </v-container>
</template>
<script>
import addresses from '../files/addresses.json'
import countrys from '../files/countrys.json'
import axios from 'axios'

export default {
    name: "registration",
    props: {
        loadedConfig: {
            type: Object,
            required: false,
            default: () => {}
        }
    },

    data () {
        return {
            window: {
                width: 0,
                height: 0
            },
            loadscreen: false,
            inputRueSearch: '',
            inputRueSearchDebounced: '',
            debouncedTimer: 0,
            addresses: addresses,
            menu: false,
            date: '',
            inputNumero: '',
            inputrue: '',
            inputcodepostal: '',
            countrys: countrys,
            inputlocalite: '',
            inputpays: '',
            nom: '',
            prenom: '',
            termsAndConditions: false,
            timeout: {},
            phone: '',
            email: '',
            termsAndConditionsDialog: false,
            keydownrue: '',
            autoselectMenu: false,
            headers: {
                timeout: 15000,
                headers: {
                    'amvc-api-device': localStorage.getItem('uuid')
                }
            }
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },

    mounted () {
        if (this.$refs && this.$refs.nom) {
            this.$refs.nom.focus()
            this.$refs.nom.hasInput = true
            this.$refs.nom.hasFocused = true
        }
    },
    computed: {
        fieldsStyle () {
            let buttonColorInner = 'white'
            let buttonColor = 'black'
            if (this.loadedConfig && this.loadedConfig.buttonColorInner) {
                buttonColorInner = this.loadedConfig.buttonColorInner
            }
            if (this.loadedConfig && this.loadedConfig.buttonColor) {
                buttonColor = this.loadedConfig.buttonColor
            }
            return {
                borderRadius: "8px !important",
                fontFamily: "Roboto !important",
                fontSize: "20px !important",
                color: buttonColorInner,
                backgroundColor: buttonColor
            }
        },

        backgroundColorStyle () {
            let color = 'white'
            if (this.loadedConfig && this.loadedConfig.appBackgroundColor) {
                color = this.loadedConfig.appBackgroundColor
            }
            return {
                backgroundColor: color
            }
        },

        fontColorStyle () {
            let color = 'white'
            if (this.loadedConfig && this.loadedConfig.fontColor) {
                color = this.loadedConfig.fontColor
            }
            return {
                color: color
            }
        },

        fieldsTitle () {
            let color = 'white'
            if (this.loadedConfig && this.loadedConfig.fontColor) {
                color = this.loadedConfig.fontColor
            }
            return {
                fontFamily: "Roboto !important",
                fontSize: "26px !important",
                color: color
            }
        },

        filteredRueMappedAddressesSet () {
            if (this.filteredRueMappedAddresses && this.filteredRueMappedAddresses.length) {
                return true
            } else {
                return false
            }
        },

        canSubmit () {
            if (this.nom && this.prenom && this.termsAndConditions && !this.loadscreen) {
                return true
            } else {
                return false
            }
        },

        computedDateFormatted () {
            return this.formatDate(this.date)
        },

        filteredRueMappedAddresses () {
            if (this.inputRueSearchDebounced && this.inputRueSearchDebounced.length > 2) {
                let filter = this.mappedAddresses.filter((element) => {
                    return element.RUE.toLocaleLowerCase().indexOf(this.inputRueSearchDebounced.toLocaleLowerCase()) > -1
                })
                if (filter && filter.length) {
                    return filter
                } else {
                    return []
                }
            }

            return []
        },

        mappedAddresses () {
            return this.addresses.map((element) => {
                let output = element
                if (element.CODE_POSTAL) {
                    element.plz = element.CODE_POSTAL.split('-')[1]
                } else {
                    element.plz = 'NULL'
                }
                if (element.RUE && element.CODE_POSTAL && element.LOCALITE) {
                    element.full = element.RUE + ' ' + element.CODE_POSTAL + ' ' + element.LOCALITE
                } else {
                    element.full = 'NULL'
                }
                return output
            }).sort((a, b) => a.plz.localeCompare(b.plz));
        },

        rue () {
            if (this.inputrue) {
                if (typeof this.inputrue === 'string') {
                    return this.inputrue
                } else if (typeof this.inputrue === 'object') {
                    if (this.inputrue && this.inputrue.RUE) {
                        return this.inputrue.RUE
                    } else {
                        return ''
                    }
                } else {
                    return ''
                }
            } else {
                return ''
            }
        }
    },

    watch: {
        inputRueSearch () {
            clearTimeout(this.debouncedTimer)
            this.debouncedTimer = setTimeout(() => {
                this.inputRueSearchDebounced = this.inputRueSearch
            }, 300)
        },

        keydownrue () {
            if (!this.keydownrue) {
                if (typeof this.inputcodepostal === 'object') {
                    this.inputcodepostal = ''
                }
                if (typeof this.inputlocalite === 'object') {
                    this.inputlocalite = ''
                }
                if (typeof this.inputpays === 'object') {
                    this.inputpays = ''
                }
            }
        },

        menu (val) {
            let year = new Date().getFullYear() -18
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
            val && setTimeout(() => (this.$refs.picker.inputYear = year))
            val && setTimeout(() => (this.$refs.picker.tableDate = year.toString() + '-01'))

        },

        inputrue () {
            if (this.inputrue) {
                if (typeof this.inputrue === 'object') {
                    this.inputcodepostal = this.inputrue
                    this.inputlocalite = this.inputrue
                    this.inputpays = {
                        code: 'LU',
                        name: 'Luxembourg'
                    }
                }
            } else {
                if (typeof this.inputcodepostal === 'object') {
                    this.inputcodepostal = ''
                }
                if (typeof this.inputlocalite === 'object') {
                    this.inputlocalite = ''
                }
                if (typeof this.inputpays === 'object') {
                    this.inputpays = ''
                }
            }
        },

        inputcodepostal () {
            if (this.inputcodepostal) {
                if (typeof this.inputcodepostal === 'object') {
                    this.inputlocalite = this.inputcodepostal
                    this.inputpays = {
                        code: 'LU',
                        name: 'Luxembourg'
                    }
                }
            }
            // else {
            //     if (typeof this.inputrue === 'object') {
            //         this.inputrue = ''
            //     }
            //     if (typeof this.inputlocalite === 'object') {
            //         this.inputlocalite = ''
            //     }
            //     if (typeof this.inputpays === 'object') {
            //         this.inputpays = ''
            //     }
            // }
        },

        inputlocalite () {
            if (this.inputlocalite) {
                if (typeof this.inputlocalite === 'object') {
                    this.inputpays = {
                        code: 'LU',
                        name: 'Luxembourg'
                    }
                }
            }
        }
    },

    methods: {
        inputRueFilter (item, queryText, itemText) {
            if (queryText && queryText.length > 2) {
                return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
            }
            return false
        },

        input (event) {
            if (event.target.value && event.target.value.length > 2) {
                this.autoselectMenu = true
            } else {
                this.autoselectMenu = false
            }

            this.keydownrue = event.target.value
        },

        termsAndConditionsAccepted () {
            this.termsAndConditionsDialog = false
            this.termsAndConditions = true
        },

        goToMainMenu () {
            this.$router.push({
                name: 'home'
            })
        },

        submitForm () {
            if (this.nom && this.prenom && this.termsAndConditions) {
                this.loadscreen = true
                let body = {
                    firstName: this.prenom,
                    lastName: this.nom,
                    acceptTermsAndConditions: this.termsAndConditions,
                    addresses: [],
                    communicationMechanisms: []
                }
                if (this.date) {
                    body.dateOfBirth = this.date
                }
                if (this.email) {
                    let obj = {}
                    obj.type = 'EMAIL'
                    obj.data = this.email
                    body.communicationMechanisms.push(obj)
                }
                if (this.phone) {
                    let obj = {}
                    obj.type = 'PHONE'
                    obj.data = this.phone
                    body.communicationMechanisms.push(obj)
                }
                let address = {}
                if (this.inputNumero) {
                    address.buildingNumber = this.inputNumero
                } else {
                    address.buildingNumber = ''
                }

                if (this.inputrue) {
                    if (typeof this.inputrue === 'object') {
                        address.street = this.inputrue.RUE
                    } else {
                        address.street = this.inputrue
                    }
                } else {
                    address.street = ''
                }

                if (this.inputlocalite) {
                    if (typeof this.inputlocalite === 'object') {
                        address.city = this.inputlocalite.LOCALITE
                    } else {
                        address.city = this.inputlocalite
                    }
                } else {
                    address.city = ''
                }

                if (this.inputcodepostal) {
                    if (typeof this.inputcodepostal === 'object') {
                        address.zip = this.inputcodepostal.CODE_POSTAL
                    } else {
                        address.zip = this.inputcodepostal
                    }
                } else {
                    address.zip = ''
                }

                if (this.inputpays) {
                    if (typeof this.inputpays === 'object') {
                        address.countryCode = this.inputpays.code
                    } else {
                        address.countryCode = this.inputpays
                    }
                } else {
                    address.countryCode = ''
                }
                if (address && Object.keys(address)) {
                    body.addresses.push(address)
                }

                if (this.loadedConfig && this.loadedConfig.backendUrl) {
                    axios.post(this.loadedConfig.backendUrl, body, this.headers).then((element) => {
                        this.$router.push({
                            name: 'home',
                            params: {
                                firstName: this.prenom,
                                lastName: this.nom,
                                custumerId: element.data.entityId
                            }
                        })
                    })
                    .finally(() => {
                        this.loadscreen = false
                    })
                }
            }
        },

        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },

        formatDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        }
    }
}
</script>
<style>
.cornerslogo {
    display: flex;
    justify-content: center;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
/* .fieldsStyle {
    border-radius: 8px !important;
    font-family: 'Roboto' !important;
    font-size: 20px !important;
    color: white !important;
    background-color: #2E2D2C !important;
} */
/* .fieldsTitle {
    font-family: 'Roboto' !important;
    font-size: 26px !important;
} */

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #999592 !important;
    color: white !important;
}
</style>
