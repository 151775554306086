<template>
    <v-app :style="appClass">
        <v-main>
            <router-view v-if="loadedConfig" :loaded-config="loadedConfig" />
            <div v-else>
                {{ errorMessage || 'Config not loaded' }}
            </div>
        </v-main>
    </v-app>
</template>
<script>
import axios from 'axios'
export default {
  name: 'App',
  data () {
      return {
          loadedConfig: null,
          errorMessage: '',
          headers: {
              timeout: 15000,
              headers: {
                  'amvc-api-device': ''
              }
          }
      }
  },
  computed: {
      appClass () {
          let data = {
              backgroundColor: 'white'
          }
          if (this.loadedConfig && this.loadedConfig.appBackgroundColor) {
              data.backgroundColor = this.loadedConfig.appBackgroundColor
          }

          return data
      }
  },

  mounted () {
      if (this.$router.currentRoute.path === '/') {
          this.$router.push({
              name: 'home',
              params: {
                  branch: 'thecorners'
              }
          })
      }
      if (!localStorage.getItem('uuid')) {
          this.uuidv4()
          localStorage.setItem('uuid', this.uuidv4());
          this.headers.headers['amvc-api-device'] = localStorage.getItem('uuid')
      } else {
          this.headers.headers['amvc-api-device'] = localStorage.getItem('uuid')
      }
      this.getConfig()
  },

  watch: {
      '$route' (route) {
          if (route.matched) {
              if (route.path === '/') {
                  this.$router.push({
                      name: 'home',
                      params: {
                          branch: 'thecorners'
                      }
                  })
              }
              this.getConfig()
          }
      }
  },

  methods: {
    getConfig () {
        let branch = this.$router.currentRoute.params.branch
        if (branch) {
            this.errorMessage = ''
            axios.get('https://backend.thecorners.lu/api/branchconfigclientscreen/' + this.$router.currentRoute.params.branch, this.headers).then((element) => {
                this.loadedConfig = element.data
            }).catch((err) => {
                this.errorMessage = err.response.data.message
                this.loadedConfig = null
            })
        } else {
             this.loadedConfig = null
        }
    },

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
  }
};
</script>
