import home from '../components/home.vue'
import register from '../components/registration.vue'

export default [
    {
        name: 'register',
        path: '/register/:branch',
        component: register
    },
    {
        name: 'home',
        path: '/:branch',
        component: home
    }
]
